<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">
          Use Cases / Summarize a Case / {{ this.$route.params.id }}
        </h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <!-- <PageLoader v-if="loading" /> -->
        <!-- Add v-else in div below -->

        <div class="case-summary-container">
          <section class="file-upload-status">
            <h2 class="status-title">Uploaded 5 Files</h2>
            <button class="view-files-button">View Uploaded Files</button>
            <div class="security-notice">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee5e1527866344adfafc18849c89c62dd76f2b9c7ded99add1ae660ece66da12?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                alt="Security icon"
                class="security-icon"
              />
              <p class="security-text">
                Your files will be securely stored in NeXa, accessible only to
                you and your workspace admins, and you can delete them at any
                time.
              </p>
            </div>
          </section>

          <main class="case-details-container">
            <header class="case-header">
              <div class="case-title-section">
                <h1 class="case-title">Summarize a Case</h1>
              </div>
              <div class="action-buttons">
                <button class="action-button copy-button" tabindex="0">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b44c86e6cccfd119bd0ae151720d5bcb372679b6fd25fe58afd18b9b6a9484b5?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                    alt="Copy icon"
                    class="button-icon"
                  />
                  <span>Copy</span>
                </button>
                <button class="action-button export-button" tabindex="0">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4fab9f61b194c622c351c36fe680a6e60906c3e4e1fa589231b48ceb16f4a2e?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                    alt="Export icon"
                    class="button-icon"
                  />
                  <span>Export</span>
                </button>
              </div>
            </header>

            <section class="document-section">
              <div class="document-header">
                <h2 class="document-title">Document 1:</h2>
                <span class="document-name">Statement_of_Claim.pdf</span>
              </div>

              <h3 class="section-title">Case Info</h3>

              <div class="info-grid">
                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Case Title</h4>
                    <p class="info-value">Smith v. Johnson Contract Dispute</p>
                  </div>
                  <div class="info-item">
                    <h4 class="info-label">Case Number</h4>
                    <p class="info-value">2023-CV-145</p>
                  </div>
                </div>

                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Court</h4>
                    <p class="info-value">
                      Superior Court of California, Los Angeles County
                    </p>
                  </div>
                  <div class="info-item">
                    <h4 class="info-label">Presiding Judge</h4>
                    <p class="info-value">Hon. Susan Taylor</p>
                  </div>
                </div>

                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Plaintiff</h4>
                    <p class="info-value">John Smith</p>
                  </div>
                  <div class="info-item">
                    <h4 class="info-label">Defendant</h4>
                    <p class="info-value">Mary Johnson</p>
                  </div>
                </div>
              </div>
            </section>

            <hr class="section-divider" />

            <section class="summary-section">
              <h3 class="section-title">Brief Summary</h3>
              <p class="summary-text">
                John Smith (Plaintiff) entered into a contract with Mary Johnson
                (Defendant) for the sale of goods. Smith claims Johnson breached
                the contract by failing to deliver the goods by the agreed-upon
                deadline, causing financial loss. Johnson argues that the delay
                was due to unforeseen disruptions from third-party vendors,
                invoking the force majeure clause as her defense.<br /><br />
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi
                neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium,
                ligula sollicitudin laoreet viverra, tortor libero sodales leo,
                eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.
                Suspendisse potenti.<br /><br />
                Sed egestas, ante et vulputate volutpat, eros pede semper est,
                vitae luctus metus libero eu augue. Morbi purus libero, faucibus
                adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                elementum hendrerit tortor. Sed semper lorem at felis.
                Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod
                dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu,
                dapibus eu, fermentum et, dapibus sed, urna.
              </p>
            </section>

            <hr class="section-divider" />

            <section class="key-points-section">
              <h3 class="section-title">Key Points</h3>

              <div class="legal-issues">
                <h4 class="subsection-title">Legal Issues</h4>
                <ul class="issues-list">
                  <li>
                    Did the force majeure clause in the contract excuse
                    Johnson's delayed performance?
                  </li>
                  <li>Was Johnson obligated to notify Smith of the delay?</li>
                </ul>
              </div>

              <div class="factual-disputes">
                <h4 class="subsection-title">Factual Disputes</h4>
                <div class="dispute-item">
                  <p class="dispute-question">
                    <span class="label-text">Dispute:</span> When did Johnson
                    notify Smith of the delivery delay?
                  </p>
                  <p class="dispute-action">
                    <span class="label-text">Suggested Action:</span>
                    Further discovery might be needed to clarify the timeline
                    and any communication gaps. Look into email or phone records
                    to establish whether the delay notice was provided within a
                    reasonable timeframe.
                  </p>
                </div>

                <div class="dispute-item">
                  <p class="dispute-question">
                    <span class="label-text">Dispute:</span> Did the delay in
                    delivery impact Smith's business significantly enough to
                    warrant full damages?
                  </p>
                  <p class="dispute-action">
                    <span class="label-text">Suggested Action:</span>
                    Review Smith's financial statements during the delivery
                    period to quantify losses. Witness testimony from Smith's
                    employees might strengthen the claim of business disruption.
                  </p>
                </div>

                <p class="value-note">
                  NeXa adds value by not only identifying these disputes but
                  also suggesting areas for further action to resolve them. Each
                  factual dispute is actionable.
                </p>
              </div>

              <div class="key-evidence">
                <h4 class="subsection-title">Key Evidence</h4>
                <div class="dispute-item">
                  <p class="dispute-question">
                    <span class="label-text">Dispute:</span> When did Johnson
                    notify Smith of the delivery delay?
                  </p>
                  <p class="dispute-action">
                    <span class="label-text">Suggested Action:</span>
                    Further discovery might be needed to clarify the timeline
                    and any communication gaps. Look into email or phone records
                    to establish whether the delay notice was provided within a
                    reasonable timeframe.
                  </p>
                </div>

                <div class="dispute-item">
                  <p class="dispute-question">
                    <span class="label-text">Dispute:</span> Did the delay in
                    delivery impact Smith's business significantly enough to
                    warrant full damages?
                  </p>
                  <p class="dispute-action">
                    <span class="label-text">Suggested Action:</span>
                    Review Smith's financial statements during the delivery
                    period to quantify losses. Witness testimony from Smith's
                    employees might strengthen the claim of business disruption.
                  </p>
                </div>

                <p class="value-note">
                  NeXa adds value by not only identifying these disputes but
                  also suggesting areas for further action to resolve them. Each
                  factual dispute is actionable.
                </p>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import countryImages from "../../../assets/country/index";
// import PageLoader from "../../PageLoader.vue";
import sidebar from "../sidebar.vue";
// import usecase from "@/store/usecase.js";
import { ExportDataPDF } from "@/store/utils";
// import VueMarked from "vue-marked";
export default {
  components: {
    sidebar,
    // VueMarked,
    // PageLoader,
    //   ChatBox,
  },
  data() {
    return {
      loading: true,
      duration: -1,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCopied: false,
      isDropdown: false,
      showSummary: false,
      countryImages,
      files: [],
      values: {},
      documentBrief: {},
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleSummary() {
      this.showSummary = !this.showSummary;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "BuildArgumentResult" });
    },
    copy() {
      const contentElement = document.querySelector("article").innerText;

      navigator.clipboard
        .writeText(contentElement)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Build An Argument: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement = document.querySelector("article").innerHTML;
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          tempDiv.innerHTML = followUpQuestion + contentElement;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Build An Argument: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}`
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    // usecase.GetResult(this.$route.params.id ?? "").then((response) => {
    //   try {
    //     const document = response.data.data.questionDocuments.find(
    //       (item) => item.id == this.$route.params.docId
    //     );
    //     this.documentBrief = document;
    //     if (document) {
    //       this.values = JSON.parse(document.values[0].value).output;
    //     }
    //     this.loading = false;
    //   } catch (error) {
    //     this.$toast.error("An error occurred while fetching the document");
    //     console.log(error.message);
    //   }
    // });
  },
};
</script>

<style scoped>
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.case-summary-container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  font-family: Poppins, sans-serif;
}

.file-upload-status {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 16px 20px;
}

.status-title {
  font-size: 20px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 600;
  line-height: 1.4;
}

.view-files-button {
  border-radius: 4px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Status-Blue, #1890ff);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  padding: 2px 8px;
  margin-top: 16px;
  border: none;
  cursor: pointer;
}

.security-notice {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
}

.security-icon {
  width: 18px;
  height: 18px;
}

.security-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  line-height: 24px;
}

.case-details-container {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px rgba(170, 171, 175, 0.6);
  margin-top: 32px;
  padding: 22px 24px;
}

.case-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.case-title {
  color: var(--Primary-Blue, #0e4485);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.action-buttons {
  display: flex;
  gap: 16px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.copy-button {
  color: var(--Primary-Blue, #0e4485);
  background: transparent;
}

.export-button {
  color: var(--Neutral-White, #fff);
  background: var(--Primary-Blue, #0e4485);
}

.button-icon {
  width: 18px;
  height: 18px;
}

.document-section {
  margin-top: 32px;
}

.document-header {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.document-title {
  color: var(--Primary-Blue, #0e4485);
}

.document-name {
  color: var(--Neutral-Black, #383a3e);
}

.section-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 16px;
}

.info-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.info-card {
  display: flex;
  gap: 10px;
  padding: 4px 0;
}

.info-item {
  flex: 1;
  min-width: 240px;
}

.info-label {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.info-value {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
}

.section-divider {
  border: none;
  border-top: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  margin: 32px 0;
}

.summary-text {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}

.subsection-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 8px;
}

.issues-list {
  list-style: none;
  padding: 0;
  margin: 8px 0;
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
}

.issues-list li {
  margin-bottom: 8px;
}

.dispute-item {
  margin-top: 16px;
}

.dispute-question,
.dispute-action {
  margin-bottom: 8px;
}

.label-text {
  color: var(--Neutral-Dark-Grey, #86888d);
}

.value-note {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  margin-top: 16px;
}

@media (max-width: 991px) {
  .case-details-container {
    padding: 20px;
  }

  .case-header {
    flex-wrap: wrap;
  }

  .info-card {
    flex-wrap: wrap;
  }

  .info-item {
    flex-basis: 100%;
  }
}
</style>
